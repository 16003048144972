import React from 'react'
import { useSelector } from 'react-redux';
import { getUrlParams } from '../../helpers/location';
import { navigateTo } from '../../components/CrossPlatformLink';
import * as Api from '../../apis';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Layout from '../../components/v2/Layout';
import Button from '../../components/v2/Button';

const Page = ({ location }) => {
  const params = getUrlParams(location.search);
  const { code } = params;
  const { source } = useSelector(state => state.session);

  const svgString2Image = (svgString, width, height, format) => {
    format = format ? format : 'png';
    const svgData = [svgString]
      .map(encodeURIComponent)
      .map(unescape)
      .map(btoa)
      .map(v => `data:image/svg+xml;base64,${v}`)[0];
    const canvas = document.createElement('canvas');
    canvas.width = width;
    canvas.height = height;
    const image = new Image();
    image.src = svgData;
    image.onload = function () {
      const context = canvas.getContext('2d');
      context.drawImage(image, 0, 0);
      const dataURL = canvas.toDataURL('image/' + format);
      if (window.navigator.msSaveBlob) {
        window.navigator.msSaveBlob(canvas.msToBlob(), 'qr_code.png');
      } else {
        const a = document.createElement('a');
        const my_evt = new MouseEvent('click');
        a.download = 'qr_code.png';
        a.href = dataURL;
        a.dispatchEvent(my_evt);
      }
    };
  }

  const handleDownload = async () => {
    const contentQR = await Api.getPromptpay(code);
    svgString2Image(contentQR, 740, 1050, 'png');
  }

  if (!code) {
    return false;
  }
  
  return (
    <Layout bg="bg4" title="">
      <Box p={2}>
        <Typography variant="h5" component="h1" color="textPrimary">
          ดาวน์โหลด QR Code ด้านล่างเพื่อชำระเงิน
        </Typography>
        <Typography variant="small" component="p" color="textPrimary">
          กรุณาชำระผ่าน QR Code นี้ภายใน 15 นาที
        </Typography>
        <img src={code} style={{ maxWidth: '100%' }} alt="promptpay"/>
        <Button
          fullWidth
          size="large"
          onClick={handleDownload}
        >
          ดาวน์โหลด QR Code
        </Button>
        <Box mt={1}>
          <Button
            fullWidth
            variant="outlined"
            size="large"
            onClick={() => navigateTo(source, 'me.moronline://exit')}
          >
            ปิดหน้านี้
          </Button>
        </Box>
      </Box>
    </Layout >
  );
}

export default Page;